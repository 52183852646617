<template>
  <div id="header" class="header" :class="{scroll: isShowScroll || isHiddenHeader}">
    <div class="header__container container-custom">
      <div class="header-menu">
        <nuxt-link id="logo" class="logo" :to="PAGE_URLS.HOME">
          <BaseImg :relative-src="LOGO_SITE_IMAGE" alt="logo" @click="navigateToHome" />
        </nuxt-link>
        <div class="menu">
          <nuxt-link
            v-for="(item, index) in menuList"
            :id="`link-${item?.name}`"
            :key="index"
            :to="item?.activeUrl"
            class="menu__item"
            :class="[
              item?.name,
              {
                openSubMenu: openSubMenu === item?.name,
                active: item?.name === route?.name &&
                  !(route?.name === 'game-type' && route.params.type === 'da-ga') || route.fullPath.startsWith(item?.activeUrl),
                hiddenBorderBottom: openSubMenu === item?.name
              }
            ]"
            @mouseover="onMouseOver(item)"
            @mouseleave="onMouseLeave()"
          >
            <div class="menu-item__content">
              <BaseImg :relative-src="item.iconActive" :alt="item.name" class="menu-icon--default" />
              <span class="menu-title">{{ item.title }}</span>
              <span v-if="item.subMenu && item.subMenu.length" class="icon-arrow-header" />
            </div>
            <div v-if="item.subMenu && item.subMenu.length" class="sub-menu" :class="item?.name">
              <div class="sub-menu__container" :class="{ gridmenu: item.subMenu.length > 6 , sport_menu: item.type === 'sports'}">
                <div
                  v-for="(subItem, subIndex) in item.subMenu"
                  :id="`header-${subItem?.name}`"
                  :key="`${index}${subIndex}`"
                  class="sub-menu__item"
                  :class="[
                    subItem?.type,
                    subItem.alias,
                    {
                      active:
                        subItem.url === route.fullPath || `${subItem?.alias?.toLowerCase()}` === route.params.type || (subItem.alias === 'all' && route.path === '/livecasino')
                    }
                  ]"
                  @click.prevent="getLinkSubMenu(item, subItem)"
                >
                  <div class="sub-menu__item--content">
                    <BaseImg class="icon-active" :relative-src="subItem.icon" :alt="subItem.display_name" />
                    <div class="text">
                      <p>{{ subItem.display_name }}</p>
                      <div v-if="subItem.jackpot > 0" class="jackpot">
                        <AnimateCountUp class="value" :number="subItem.jackpot" :show-coin="false" />
                      </div>
                    </div>
                  </div>
                  <div v-if="!subItem?.isNotShowLine" class="line" />
                </div>
              </div>
            </div>
          </nuxt-link>
        </div>
      </div>
      <div class="header-button">
        <div class="promotion-container">
          <div class="promotion-button">
            <BaseImg relative-src="/assets/images/components/desktop/header/icon-promotion.svg" class="icon-promotion" />
            <NuxtLink class="promotion-link" :to="PAGE_URLS.PROMOTION">
              <span>Khuyến Mãi</span>
            </NuxtLink>
          </div>
          <div v-if="!currentUser" class="line" />
        </div>
        <UserNotLogin v-if="!currentUser" />
        <UserLogged v-if="currentUser" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import BaseImg from '~/components/common/base-img.vue'
import { useAppStore } from '@/store/app'
import { LOGO_SITE_IMAGE, MENUS } from '~/constants/menu'
import UserNotLogin from '~/components/desktop/header/user-not-login.vue'
import UserLogged from '~/components/desktop/header/user-logged.vue'
import AnimateCountUp from '~/components/common/animate-count-up.vue'
import { IMenuHeader, ISubMenuHeader } from '~/types/menu.type'
import { useJackpot } from '~/composables/game/useJackpot'
import { useGameStore } from '~/store/game'
import { useGame } from '~/composables/game/useGame'
import { useCasino } from '~/composables/casino/useCasino'
import { PAGE_URLS } from '~/config/page-url'
import { MODAL_TYPES } from '~/config/constant'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot, currentUser } = storeToRefs(store)
const storeGame = useGameStore($pinia)
const { providerGames, providerCasino } = storeToRefs(storeGame)
const openSubMenu = ref('')
const { fetchLogoHeader } = useHeader()
const { onSumJackpotInterval, onSumJackpotIntervalClear } = useJackpot()
const { fetchCongGame } = useGame()
const { fetchGameCasino } = useCasino()
const { fetchListBank } = useListBank()

const route = useRoute()
const router = useRouter()
const isHiddenHeader = ref<boolean>(false)
const isShowScroll = ref<boolean>(false)
const loading = ref(true)
const { openModalWithNavigate } = useModal()
const { isLogged } = storeToRefs(store)
const handleScroll = () => {
  isShowScroll.value = window.scrollY > 90
}
onMounted(async () => {
  await fetchLogoHeader()
  await fetchListBank()
})
const onClickMenu = () => {
  openSubMenu.value = ''
}
const getLinkSubMenu = (item: IMenuHeader, subItem: ISubMenuHeader) => {
  onClickMenu()
  if (item.type !== 'sports') {
    let url = `${item.activeUrl}/${subItem.alias}`
    if (subItem.alias === 'all') {
      url = item.activeUrl
    }
    router.push(url)
  } else if (subItem.isCheckLogin) {
    openPage(subItem.url)
  } else {
    router.push(subItem.url)
  }
}

const openPage = (url: string) => {
  if (isLogged.value) {
    router.push(url)
  } else {
    navigateTo({
      query: { ...route.query, popup: MODAL_TYPES.LOGIN }
    })
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', url)
  }
}
watch(isLogged, (newVal) => {
  if (newVal) {
    setTimeout(() => {
      const openUrl = route.query.openUrl as string
      if (openUrl) {
        router.push({ path: openUrl })
      }
    }, 500)
  }
})

const onMouseOver = (item: IMenuHeader) => {
  if (item.subMenu && item.subMenu.length) {
    openSubMenu.value = item?.name
  }
}
const onMouseLeave = () => {
  openSubMenu.value = ''
}

watch(isLogged, () => {
  fetchListBank()
})

const fetchData = async () => {
  const promises = []
  if (providerGames.value?.length === 0) {
    promises.push(fetchCongGame())
  }
  if (providerCasino.value?.length === 0) {
    promises.push(fetchGameCasino())
  }

  await Promise.allSettled(promises)
}

watch(() => route.fullPath, () => {
  openSubMenu.value = ''
})

const navigateToHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  if (route.fullPath === '/') {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}

const { data } = await useAsyncData('dataInitialization', async () => { await fetchData() })

onMounted(() => {
  onSumJackpotInterval()
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  onSumJackpotIntervalClear()
  window.removeEventListener('scroll', handleScroll)
})

const menuList = reactive<IMenuHeader[]>([])

useAsyncData(() =>
  nextTick(() => {
    menuList.push(...MENUS)
    setTimeout(() => {
      loading.value = false
    }, 0)
  })
)

const getSumJackpot = (aliasMenu: string) => {
  switch (aliasMenu) {
    case 'no-hu': {
      return sumJackpot.value.jackpotNohu
    }
    case 'ban-ca': {
      return sumJackpot.value.jackpotFishing
    }
    case 'table-game': {
      return sumJackpot.value.jackpotIngame
    }
    default: {
      return 0
    }
  }
}

watchEffect(() => {
  isHiddenHeader.value = Boolean(route.name === 'the-thao-id')
  const updateSubMenu = (submenu: ISubMenuHeader[], type: string) => {
    const targetItem = menuList.find((item) => item.type === type)
    if (targetItem && submenu?.length) {
      let updatedSubMenu = submenu.map((item) => ({
        ...item,
        jackpot: getSumJackpot(item.alias)
      }))
      if (type === 'livecasino') {
        updatedSubMenu = updatedSubMenu.slice(1)
      }

      targetItem.subMenu = updatedSubMenu.filter((item) => item.alias !== 'da-ga')
    }
  }

  const subMenusToUpdate = [
    { submenu: providerGames.value, type: 'games' },
    { submenu: providerCasino.value, type: 'livecasino' }
  ]
  subMenusToUpdate.forEach(({ submenu, type }) => updateSubMenu(submenu, type))
})
</script>

<style scoped lang="scss" src="public/assets/scss/components/desktop/header/index.scss"></style>
